<template>
  <div class="card shadow-sm border-0" :class="`bg-${color} text-${textColor}`">
    <div class="card-body pb-0 d-flex justify-content-between">
      <div>
        <div v-if="header" class="text-value-lg">{{header}}</div>
        <div v-if="text">{{text}}</div>
      </div>
      <slot></slot>
    </div>
    <slot name="footer"></slot>
  </div>
</template>

<script>
export default {
  name: 'AdvanceWidgetDropdown',
  props: {
    textColor: String,
    color: String,
    header: String,
    text: String
  }
}
</script>
